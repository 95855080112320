<template>
  <div class="create-form">
    <div class="create-form__header">
      <span
        class="titlex"
        :style="{
          fontSize: `${$vuetify.breakpoint.mdAndDown ? '20px' : '32px'}`,
        }"
        >Create Form</span
      >

      <div class="create-form__header--actions">
        <v-btn
          v-if="canPreview && !isSubmitting"
          dark
          elevation="0"
          class="text-capitalize"
          style="height: 54px; background: #d9dee1; border-radius: 4px"
          @click="showPreview = !showPreview"
          :style="{
            width: `${$vuetify.breakpoint.mdAndDown ? '130px' : '215px'}`,
          }"
        >
          <img :src="require('@/assets/pbot_icons/view.svg')" />
          <span
            style="
              padding-left: 8px;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.636364px;
              color: var(--v-primary-base);
            "
            :style="{
              fontSize: `${$vuetify.breakpoint.mdAndDown ? '12px' : '14px'}`,
            }"
          >
            {{ !showPreview ? "Preview" : "Edit" }}
          </span>
        </v-btn>

        <v-btn
          :loading="isSubmitting"
          @click="submitForm"
          dark
          class="text-capitalize"
          style="
            height: 54px;
            background: var(--v-primary-base);
            box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
            border-radius: 4px;
          "
          :style="{
            width: `${$vuetify.breakpoint.mdAndDown ? '130px' : '215px'}`,
            opacity: `${!canPreview ? '0.8' : '1'}`,
            boxShadow: `${
              canPreview ? '0px 12px 22px rgba(0, 0, 0, 0.24)' : 'none'
            }`,
          }"
        >
          <img :src="require('@/assets/pbot_icons/add-form.svg')" />
          <span
            style="
              padding-left: 8px;
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              letter-spacing: 0.636364px;
              color: #ffffff;
            "
            :style="{
              fontSize: `${$vuetify.breakpoint.mdAndDown ? '12px' : '14px'}`,
            }"
          >
            Publish
          </span>
        </v-btn>
      </div>
    </div>
    <div v-if="formDetails" class="create-form__canvas-area">
      <div class="form-actions">
        <v-switch
          color="#19283D"
          hide-details="auto"
          class="m-0 p-0"
          v-model="formDetails.is_private"
          label="This form is Private"
        ></v-switch>
      </div>
      <div class="form-canvas">
        <hyphen-form-builder
          id="form-builder"
          :details.prop="{
            name: formDetails.form_title,
            description: formDetails.form_description,
          }"
          @schema="getSchema"
          @canPreview="setPreviewState"
          :pre-schema.prop="preSchema"
          :show-preview="showPreview"
          :form-id="orgHypnId"
          :hyphen-token="token"
          :hyphen-base-url="getBaseUrl()"
        />
      </div>
    </div>
    <feed-back-modal ref="feedBackModal" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FeedBackModal from "../../includes/overlays/createForm/feedBackModal.vue";
export default {
  components: {
    FeedBackModal,
  },
  data() {
    return {
      canPreview: false,
      showPreview: false,
      formBuilder: null,
      addUploadInput: null,
      field_names: [],
      isSubmitting: false,
      form_hypn_id: "",
      formBaseUrl:
        process.env.NODE_ENV === "production"
          ? "https://hypn.so/"
          : "https://dev.hypn.so/",
      form_id: "",
      configuration: {
        formTitle: "",
        isPrivate: false,
        formId: "",
      },
      bill: [],
      formDetails: null,
      formData: null,
    };
  },
  mounted() {
    this.formDetails = JSON.parse(sessionStorage.getItem("formDetails"));

    if (!this.formDetails) {
      this.$router.push({ name: "forms" });
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      organId: "auth/organizationId",
      token: "auth/token",
    }),

    formName() {
      return this.formDetails?.form_title?.replaceAll("_", " ");
    },

    formPayload() {
      return {
        ...this.formDetails,
        field_names: this.field_names,
        form_fields: this.formData,
      };
    },

    preSchema() {
      if (
        this.formDetails &&
        (this.formDetails.is_payment || this.formDetails.form_type === "api")
      ) {
        const data = [
          {
            type: "group",
            properties: [
              {
                id: this.uniqueId(),
                type: "input",
                value: "",
                label: "Email",
                key: "email",
                required: true,
                locked: true,
                attributes: {
                  type: "email",
                  placeholder: "Enter Your Email Address",
                  description: `Your registered email with ${
                    this.$orgDetails.orgname || "this organization"
                  }`,
                },
              },
            ],
          },
        ];

        return data;
      } else return [];
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    getSchema(val) {
      this.formData = val.detail[0];
    },

    getBaseUrl() {
      return process.env.VUE_APP_BASEURL;
    },

    async submitForm() {
      try {
        this.isSubmitting = true;
        await this.$store
          .dispatch("formBuilder/createForm", this.formPayload)
          .then((res) => {
            this.form_hypn_id = res.data.data.hypn_id;
            this.form_id = res.data.id;
            this.updateSingleForm(res.data);
            this.showToast({
              sclass: "success",
              show: true,
              message: "created Form " + this.formName + " successfully",
              timeout: 3000,
            });

            this.$refs.feedBackModal.openModal({
              ...res.data.data,
              id: res.data.id,
            });
          });
        sessionStorage.removeItem("formDetails");
      } catch (error) {
        if (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: "Form " + this.formName + " failed to create",
            timeout: 3000,
          });
        }
      } finally {
        this.isSubmitting = false;
      }
    },

    updateSingleForm(data) {
      this.$store.dispatch("formBuilder/updateSingleFormStoreData", data);
    },

    setPreviewState(val) {
      this.canPreview = val.detail[0];
    },

    addToFieldNames() {
      if (this.formDetails?.form_type === "api") {
        this.formDetails?.bill.forEach((item) => {
          if (this.field_names.find((item) => item.key === "bill")) return;

          this.field_names.push({
            label: item.name,
            key: item.name.replaceAll(" ", "_"),
            type: "money",
            for: "bill",
            value: item.amount,
          });
        });
      }
    },

    addSubmittedBy() {
      if (this.field_names.find((item) => item.key === "submitted_by")) return;

      this.field_names.push({
        key: "submitted_by",
        type: "input",
        label: "Submitted By",
      });
    },

    addEntryIdObject() {
      if (this.field_names.find((item) => item.key === "entry")) return;

      this.fie1d_names.push({
        key: "entry",
        type: "input",
        label: "Entry ID",
      });
    },

    checkFiles(val) {
      this.addUploadInput = val;
    },

    randomAlphabet() {
      const [min, max] = [1, 5]; // random length of alphabets
      const alphabets = "abcdefghijklmnopqrstuvwxyz";
      const randomLength = Math.floor(Math.random() * (max - min + 1)) + min;
      let result = "";
      for (let i = 0; i < randomLength; i++) {
        result += alphabets.charAt(
          Math.floor(Math.random() * alphabets.length)
        );
      }

      return result;
    },

    randomNumber() {
      return Math.floor(Math.random() * 4500);
    },

    uniqueId() {
      return `${this.randomAlphabet()}${Date.now()}${this.randomNumber()}`;
    },
  },
  watch: {
    formData: {
      handler(val) {
        if (val) {
          const data = [];
          val.map((item) => {
            if (item.type === "group" && item.properties.length > 0) {
              item.properties.map((i) => {
                data.push(i);
              });
            }
          });

          this.field_names = data.map((item) => {
            return {
              id: item.id,
              type: item.type === "input" ? item.attributes.type : item.type,
              label: item.label,
              value: item.value,
              required: item.required,
              ...(item.options && { options: item.options }), // if item.options is available, add it to the object
              key: item.label
                .toLowerCase()
                .replace(/ /g, "_")
                .replace(/[^\w-]+/g, ""),
            };
          });
        }

        this.addSubmittedBy();
        this.addToFieldNames();
      },
      deep: true,
      immediate: true,
    },
    formPayload: {
      handler(val) {
        if (process.env.NODE_ENV !== "production") {
          console.log(JSON.stringify(val, null, 2));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.create-form {
  padding: 0px 40px 20px 20px;
  margin-top: 48px;

  // mobile view
  @media (max-width: 600px) {
    padding: 0px 10px;
    margin-top: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titlex {
      font-size: 21px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      line-height: 39px;
    }

    &--actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__canvas-area {
    max-width: 600px;
    width: 100%;
    margin: 100px auto;

    .form-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;

      button {
        padding: 2px 10px !important;
        margin: 0 !important;
        color: var(--body-text, rgba(25, 40, 61, 0.8));
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;

        transform: translateY(10px);
      }
    }

    .form-canvas {
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
